import React, { useEffect } from "react";
import NastavnoOsobljeList from "./NastavnoOsobljeList";
import MainBanner from "../../../components/Baner";
import osobljeBanner from "../../../assets/nastavno-osoblje.jpg";
import { useTranslation } from "react-i18next"; // Import i18n hook

const NastavnoOsobljeComponent = () => {
  const { t, i18n } = useTranslation(); // Access translation and language functions

  useEffect(() => {
    // Meta tags
    document.title = t("nastavnoOsobljeBanner.metaTitle");
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", t("nastavnoOsobljeBanne.metaDescription"));
  }, [i18n.language, t]);

  return (
    <>
      <MainBanner
        title={t("nastavnoOsobljeBanner.bannerTitle")}
        bannerImage={osobljeBanner}
        description={t("nastavnoOsobljeBanner.bannerDescription")}
      />
      <NastavnoOsobljeList />
    </>
  );
};

export default NastavnoOsobljeComponent;
