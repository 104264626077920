import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../assets/mef-slike/404.png";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Row className="text-center">
        <Col>
          <img
            src={NotFoundImage}
            alt="not_found_image"
            style={{ display: "block", margin: "0 auto", width: "100%" }}
          />

          <h1 className="mb-3 text-white my-4">{t("notFound.title")}</h1>
          <p className="text-white mb-5">{t("notFound.description")}</p>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleGoBack}
          >
            {t("notFound.backToHomepage")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
