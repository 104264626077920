import React, { useRef } from "react";

import StudentskaLogo from "../../../assets/mef-slike/studentska-cover.jpg";
import MainBanner from "../../../components/Baner";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import OsobljeKartice from "./OsobljeKartice";

import { useTranslation } from "react-i18next";
import NastavniPlan from "./NastavniPlan";
import Kontakt from "./Kontakt";

const ContactComponent = () => {

  const bannerRef = useRef(null);
  const { t } = useTranslation();

  const items = [
    {
      nameKey: "studentska.onama",
      link: "#ostudentskoj",
    },
    {
      nameKey: "studentska.nastavniPlanovi",
      link: "#nastavni-planovi",
    },
    {
      nameKey: "studentska.osoblje",
      link: "#studentska-osoblje",
    },
    {
      nameKey: "studentska.kontakt",
      link: "#studentska-kontakt",
    },
  ];

  document.title = t("studentska.metaTitle");
    
document
  .querySelector('meta[name="description"]')
  .setAttribute("content", t("studentska.metaDescription"));

  return (
    <>
      <div ref={bannerRef}>
        <MainBanner
          bannerImage={StudentskaLogo}
          title={t("studentska.title")}
          description={t("studentska.description")}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <div className="container__wrapper bg-white" id="ostudentskoj">
        <div className="container">
          <p className="html__wrapper">
            {t("studentska.content1")}
          </p>
          <p className="html__wrapper">
            {t("studentska.content2")}
          </p>
        </div>
      </div>
      <div className="container__wrapper bg-light" id="nastavni-planovi">
        <NastavniPlan />
      </div>
      <div className="container__wrapper bg-white" id="studentska-osoblje">
        <OsobljeKartice />
      </div>
      <div className="container__wrapper bg-light" id="studentska-kontakt">
        <Kontakt />
      </div>
    </>
  );
};

export default ContactComponent;
