import React, { useRef, useState, useEffect } from "react";
import Onama from "./Fakultet/Onama";
// import MainBanner from "./Fakultet/MainBanner";
import DekanatCards from "./Fakultet/DekanatKartice";
import DekanatStatements from "./Fakultet/DekanatIzjave";
import FacultyCycles from "./Fakultet/Ciklusi";
import FacultyDepartments from "./Fakultet/Odsjeci";
import SectionNavbar from "../components/NavigacijaSekcije";
import MainBanner from "../components/Baner";
import oNamaBanner from "../assets/o_nama-2.jpg";
import { useTranslation } from "react-i18next";
import Gallery from "../components/Galerija";
import { fetchGallery } from "../services/apiService";

function Fakultet() {
  const [isSticky, setIsSticky] = useState(false); // State to track stickiness
  const [images, setImages] = useState([]);
  const bannerRef = useRef(null);
  const { t } = useTranslation();

  const items = [
    { nameKey: "navbarOfakultetu.aboutUs", link: "#onama" },
    { nameKey: "navbarOfakultetu.deansWord", link: "#dekanat-statements" },
    { nameKey: "navbarOfakultetu.facultyManagement", link: "#dekanat-cards" },
    { nameKey: "navbarOfakultetu.cycles", link: "#faculty-cycles" },
    { nameKey: "navbarOfakultetu.departments", link: "#faculty-departments" },
    { nameKey: "navbarOfakultetu.gallery", link: "#gallery" },
  ];
  // Meta Tags
  document.title = t("oFakultetuStranica.metaTitle");
    
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", t("oFakultetuStranica.metaDescription"));

  // Fetch statistics data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchGallery();
        setImages(data);
      } catch (error) {
        console.error("Error fetching stats data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div ref={bannerRef}>
        <MainBanner
          showTwo={true}
          title={t("oFakultetuStranica.mainBanner.title")}
          titleSecond={t("oFakultetuStranica.mainBanner.titleSecond")}
          firstParagraph={t("oFakultetuStranica.mainBanner.firstParagraph")}
          secondParagraph={t("oFakultetuStranica.mainBanner.secondParagraph")}
          bannerImage={oNamaBanner}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <div id="onama">
        <Onama />
      </div>
      <div id="dekanat-statements">
        <DekanatStatements />
      </div>
      <div id="dekanat-cards">
        <DekanatCards />
      </div>
      <div id="faculty-cycles">
        <FacultyCycles />
      </div>
      <div id="faculty-departments">
        <FacultyDepartments />
      </div>
      <div id="gallery">
        <Gallery images={images} />
      </div>
    </>
  );
}

export default Fakultet;
