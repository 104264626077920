import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  Pagination,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { fetchAllDocuments, getVijeceFile } from "../../../services/apiService";
import MainBanner from "../../../components/Baner";
import dokumentiLogo from "../../../assets/dokumenti.jpg";
import { AuthContext } from "../../../contexts/AuthContext";
import style from "./Dokumenti.module.css";
import { useTranslation } from "react-i18next";

const Dokumenti = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { isAuthenticated } = useContext(AuthContext);

  // Initialize filters dynamically based on authentication status
  const [selectedFirstFilter, setSelectedFirstFilter] = useState(
    !isAuthenticated ? "Svi dokumenti" : "Javnost rada fakulteta" 
  );
  const [selectedSecondFilter, setSelectedSecondFilter] = useState(
    !isAuthenticated ? "Sve kategorije" : "Materijali za Vijeća"
  );
    
  const [searchQuery, setSearchQuery] = useState("");

  const language = localStorage.getItem("language") || "bs";

  const firstFilterOptions = [
    {
      value: "Svi dokumenti",
      label: t("dokumenti.firstDropdown.sviDokumenti"),
    },
    {
      value: "Javnost rada fakulteta",
      label: t("dokumenti.firstDropdown.javnostRada"),
    },
    { 
      value: "Propisi",
      label: t("dokumenti.firstDropdown.propisi"),
    },
  ];

  const secondFilterOptions = {
    "Svi dokumenti": [
      {
        value: "Sve kategorije",
        label: t("dokumenti.secondDropdown.sviDokumenti.sve"),
      },
      {
        value: "Zakoni",
        label: t("dokumenti.secondDropdown.sviDokumenti.zakoni"),
      },
      {
        value: "Izvještaji",
        label: t("dokumenti.secondDropdown.sviDokumenti.izvjestaji"),
      },
      {
        value: "Propisi",
        label: t("dokumenti.secondDropdown.sviDokumenti.propisi"),
      },
      {
        value: "Pravilnici",
        label: t("dokumenti.secondDropdown.sviDokumenti.pravilnici"),
      },
      {
        value: "Odluke",
        label: t("dokumenti.secondDropdown.sviDokumenti.odluke"),
      },
      {
        value: "Obrasci",
        label: t("dokumenti.secondDropdown.sviDokumenti.obrasci"),
      },
      {
        value: "Konkursi",
        label: t("dokumenti.secondDropdown.sviDokumenti.konkursi"),
      },
      {
        value: "Zahtjevi",
        label: t("dokumenti.secondDropdown.sviDokumenti.zahtijevi"),
      },
      {
        value: "Ostalo",
        label: t("dokumenti.secondDropdown.sviDokumenti.ostalo"),
      },
    ],
    Propisi: [
      {
        value: "Bosne i Hercegovine",
        label: t("dokumenti.secondDropdown.propisi.bih"),
      },
      {
        value: "Federacije Bosne i Hercegovine",
        label: t("dokumenti.secondDropdown.propisi.fbih"),
      },
      {
        value: "Kantona Sarajevo",
        label: t("dokumenti.secondDropdown.propisi.ks"),
      },
      {
        value: "Univerziteta u Sarajevu",
        label: t("dokumenti.secondDropdown.propisi.unsa"),
      },
    ],
    "Javnost rada fakulteta": [
      {
        value: "Izvještaji komisije za izbore u akademska zvanja",
        label: t("dokumenti.secondDropdown.javnostRada.izvjestajiKomisije"),
      },
      {
        value: "Sjednice Vijeća",
        label: t("dokumenti.secondDropdown.javnostRada.dnevniRedVijeca"),
      },
      {
        value: "Materijali za Vijeća",
        label: t("dokumenti.secondDropdown.javnostRada.materijaliVijeca"),
      },
      {
        value: "Konkursi za izbore u akademska zvanja",
        label: t("dokumenti.secondDropdown.javnostRada.konkursiAkademska"),
      },
      {
        value: "Konkursi za prijem neakademskog osoblja",
        label: t("dokumenti.secondDropdown.javnostRada.konkursiNeakademska"),
      },
      {
        value: "Angažman akademskog osoblja",
        label: t("dokumenti.secondDropdown.javnostRada.angazmanAkademska"),
      },
      {
        value: "Sporazumi o akademskoj saradnji",
        label: t("dokumenti.secondDropdown.javnostRada.akademskaSaradnja"),
      },
      {
        value: "Odgovori na zastupnička pitanja",
        label: t("dokumenti.secondDropdown.javnostRada.odgovoriZastupnicka"),
      },
    ],
  };

  useEffect(() => {
    // Set default filters if the user is authenticated
    if (isAuthenticated) {
      setSelectedFirstFilter("Javnost rada fakulteta");
      setSelectedSecondFilter("Materijali za Vijeća");
    }
  }, [isAuthenticated]);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const data = await fetchAllDocuments(
        page,
        searchQuery,
        selectedFirstFilter,
        selectedSecondFilter
      );
      setDocuments(data.results);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [selectedFirstFilter, selectedSecondFilter, page, searchQuery]);

  const handleFirstFilterChange = (filter) => {
    setSelectedFirstFilter(filter);
    setPage(1); // Reset to the first page on filter change

    // Automatically set selectedSecondFilter based on the selectedFirstFilter
    if (filter === "Propisi" || filter === "Javnost rada fakulteta") {
      setSelectedSecondFilter(secondFilterOptions[filter][0].value); // Use the first option's value
    } else {
      setSelectedSecondFilter("Sve kategorije");
    }
  };

  const handleSecondFilterChange = (filter) => {
    setSelectedSecondFilter(filter);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1); // Reset to first page on search
  };

  const handleDownloadClick = async (file_name) => {
    try {
      // Show loading or feedback to the user if needed
      setLoading(true);

      // Call the getVijeceFile function with the slug from file_file_name
      const response = await getVijeceFile(file_name); // Assuming getVijeceFile returns the file or data

      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(count / 10);
  const renderPagination = () => (
    <Pagination
      style={{ backgroundColor: "#F3F6F8" }}
      className="justify-content-end"
    >
      <Pagination.Prev
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      />
      {[...Array(totalPages)].map((_, index) => (
        <Pagination.Item
          key={index + 1}
          active={index + 1 === page}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages}
      />
    </Pagination>
  );

  document.title = t("dokumenti.metaTitle");
    
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", t("dokumenti.metaDescription"));

  return (
    <>
      <MainBanner
        bannerImage={dokumentiLogo}
        title={t("dokumenti.banner.title")}
        description={t("dokumenti.banner.description")}
      />
      <div className="container__wrapper bg-white ">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between mb-4">
            <div className="d-flex flex-wrap flex-lg-nowrap gap-2 col-12 col-lg-6">
              {/* First Dropdown Button */}
              <div className="col-12 col-lg-auto mb-2 mb-lg-0">
                <DropdownButton
                  title={
                    firstFilterOptions.find(
                      (option) => option.value === selectedFirstFilter
                    )?.label || selectedFirstFilter
                  }
                  variant="white"
                  onSelect={(eventKey) => handleFirstFilterChange(eventKey)}
                  className={`${style.dropDownStyle} w-100 `}
                >
                  {firstFilterOptions.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      eventKey={option.value}
                      className="w-100"
                      style={{ width: "100%" }}
                    >
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              {/* Second Dropdown Button */}
              <div className="col-12 col-lg-auto mb-2 mb-lg-0">
                <DropdownButton
                  title={
                    secondFilterOptions[selectedFirstFilter].find(
                      (option) => option.value === selectedSecondFilter
                    )?.label || selectedSecondFilter
                  }
                  variant="white"
                  onSelect={(eventKey) => handleSecondFilterChange(eventKey)}
                  className={`${style.dropDownStyle} w-100`}
                >
                  {secondFilterOptions[selectedFirstFilter].map((option) => {
                    if (
                      option.value === "Materijali za Vijeća" &&
                      !isAuthenticated
                    ) {
                      return null;
                    }

                    return (
                      <Dropdown.Item
                        key={option.value}
                        eventKey={option.value}
                        className="w-100"
                        style={{
                          fontWeight:
                            option.value === "Materijali za Vijeća"
                              ? 600
                              : "normal",
                        }}
                      >
                        {option.label}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>

            {/* Search Input */}
            <div className="col-12 col-lg-auto mt-2 mt-lg-0">
              <InputGroup style={{ width: "100%" }}>
                <FormControl
                  placeholder={t("dokumenti.search")}
                  value={searchQuery}
                  onChange={handleSearch}
                  class={style.searchInputStyle}
                />
              </InputGroup>
            </div>
          </div>

          <div className="d-flex justify-content-center"></div>

          <Table hover style={{ borderTop: "none" }}>
            <thead>
              <tr style={{ backgroundColor: "#F3F6F8" }}>
                <th class={style.tableHeadingStyle}>
                  {t("dokumenti.table.title")}
                </th>

                {selectedSecondFilter !== "Materijali za Vijeća" && (
                  <th class={style.tableHeadingStyle}>
                    {t("dokumenti.table.category")}
                  </th>
                )}
                <th class={style.tableHeadingStyle}>
                  {selectedSecondFilter === "Materijali za Vijeća"
                    ? t("dokumenti.table.publicationDate")
                    : t("dokumenti.table.date")}
                </th>
                <th class={`${style.tableHeadingStyle} ${style.downloadHeading}`}>
                  {t("dokumenti.table.download")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="d-flex justify-content-center align-items-center my-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </td>
                </tr>
              ) : documents.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    {t("dokumenti.noResults")}
                  </td>
                </tr>
              ) : (
                documents.map((doc) => (
                  <tr
                    key={doc.title}
                    style={{ borderBottom: "1px solid #DDDDDD" }}
                  >
                    <td class={style.tableDataStyle}>{doc.title}</td>
                    {selectedSecondFilter !== "Materijali za Vijeća" && (
                      <td class={style.tableDataStyle}>{doc.doc_type}</td>
                    )}
                    <td class={style.tableDataStyle}>{doc.date}</td>
                    <td
                      class={style.tableDataStyle}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderBottom: "none",
                      }}
                    >
                      {selectedSecondFilter === "Materijali za Vijeća" ? (
                        <button
                          onClick={() => handleDownloadClick(doc.file_name)}
                          class={style.downloadButtonStyle}
                        >
                          <FaDownload />
                        </button>
                      ) : (
                        <a
                          href={doc.file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          <FaDownload />
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-end">{renderPagination()}</div>
        </div>
      </div>
    </>
  );
};

export default Dokumenti;
