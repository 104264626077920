import React from "react";
import Map from "./Map";
import KontaktLogo from "../../../assets/mef-slike/kontakt-cover.jpg";
import MainBanner from "../../../components/Baner";
import KontaktKartice from "./KontaktKartice";
import { useTranslation } from "react-i18next";

const ContactComponent = () => {
  const { t } = useTranslation();

  document.title = t("kontakt.metaTitle");
    
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", t("kontakt.metaDescription"));

  return (
    <>
      <MainBanner
        bannerImage={KontaktLogo}
        title={t("kontakt.title")}
        description={t("kontakt.description")}
      />
      <div className="container__wrapper bg-white">
        <Map />
      </div>
      <div className="container__wrapper bg-light">
        <KontaktKartice />
      </div>
    </>
  );
};

export default ContactComponent;
