import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  InputGroup,
  FormControl,
  Pagination,
  Card,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import "../../../styles/global.css";
import Logo from "../../../assets/mef_logo_black.png";
import { fetchAllObavijesti } from "../../../services/apiService";
import ButtonComponent from "../../../components/ButtonComponent";
import style from "./ObavijestiList.module.css";
import { useTranslation } from "react-i18next";

const firstFilterOptions = {
  bs: [
    "Sve obavijesti",
    "Javni konkursi",
    "Javne nabavke",
    "Obavijesti za studente",
    "Ostalo",
  ],
  en: [
    "All announcements",
    "Public competitions",
    "Public procurements",
    "Student notices",
    "Others",
  ],
};

const getFilterOptions = () => {
  const lang = localStorage.getItem("i18nextLng") || "bs";
  return firstFilterOptions[lang];
};

const filterTranslationMap = {
  en: {
    "All announcements": "Sve obavijesti",
    "Public competitions": "Javni konkursi",
    "Public procurements": "Javne nabavke",
    "Student notices": "Obavijesti za studente",
    Others: "Ostalo",
  },
  bs: {
    "Sve obavijesti": "Sve obavijesti",
    "Javni konkursi": "Javni konkursi",
    "Javne nabavke": "Javne nabavke",
    "Obavijesti za studente": "Obavijesti za studente",
    Ostalo: "Ostalo",
  },
};

const getBosnianFilter = (selectedFilter) => {
  const lang = localStorage.getItem("i18nextLng") || "bs";
  return filterTranslationMap[lang][selectedFilter];
};

const MyComponent = () => {
  const { i18n } = useTranslation();

  const [obavijesti, setObavijesti] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [obavijestiNum, setObavijestiNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFirstFilter, setSelectedFirstFilter] = useState(() => {
    const lang = localStorage.getItem("i18nextLng") || "bs";
    return lang === "bs" ? "Sve obavijesti" : "All announcements";
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  const itemsPerPage = 6;
  const navigate = useNavigate();

  // Define an async function to fetch data
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchAllObavijesti(
        currentPage,
        titleFilter,
        getBosnianFilter(selectedFirstFilter)
      );

      setObavijestiNum(data.count);
      setObavijesti(data.results); // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error); // Log any errors
    } finally {
      setIsLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchData(currentPage, titleFilter); // Fetch data on initial load and when currentPage changes
  }, [currentPage, titleFilter, selectedFirstFilter, i18n.language]);

  useEffect(() => {
    setSelectedFirstFilter(() => {
      return i18n.language === "bs" ? "Sve obavijesti" : "All announcements";
    });
  }, [i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    navigate("?page=1");
    setSearchParams({ page: 1 });
  };

  const handleFirstFilterChange = (filter) => {
    // const bosnianFilter = getBosnianFilter(filter);
    setSelectedFirstFilter(filter);
    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(obavijestiNum / itemsPerPage);
  return (
    <div className="py-5 bg-white">
      {/* Search Bar */}
      <Container>
        <div className="d-flex flex-wrap justify-content-between mb-4">
          <div className="col-12 col-lg-auto mb-2 mb-lg-0">
            <DropdownButton
              title={selectedFirstFilter}
              variant="white"
              onSelect={handleFirstFilterChange}
              className={`${style.dropDownStyle} w-100 `}
            >
              {getFilterOptions().map((option) => (
                <Dropdown.Item key={option} eventKey={option} className="w-100">
                  {option}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="col-12 col-lg-auto mt-2 mt-lg-0">
            <InputGroup style={{ width: "100%" }}>
              <FormControl
                placeholder={
                  localStorage.getItem("i18nextLng") === "bs"
                    ? "Pretraži"
                    : "Search"
                }
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>
        </div>

        {/* Obavijesti Container */}
        <div class={style.announcementContainer}>
          {/* Cards */}
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : obavijesti.length === 0 ? (
            <div class={style.noDataContainer}>
              <p>
                {localStorage.getItem("i18nextLng") === "bs"
                  ? "Nema podataka"
                  : "No data"}
              </p>
            </div>
          ) : (
            obavijesti.map((item, idx) => (
              <Card key={idx} className={`${style.announcementCard} mb-3`}>
                <Link
                  to={
                    i18n.language === "en"
                      ? `/en/aktuelnosti/obavijesti/${item.slug}`
                      : `/aktuelnosti/obavijesti/${item.slug}`
                  }
                  state={{ fromPage: currentPage }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Row className="h-120px d-flex justify-content-between">
                    <img
                      src={Logo} // Placeholder image URL
                      alt="Logo"
                      style={{ width: "120px", height: "90px" }}
                    />

                    <span style={{}} className={`${style.announcementDate}`}>
                      {item.date}
                    </span>
                  </Row>

                  {/* Title */}
                  <div className="d-flex flex-column justify-content-between">
                    <div className="mb-2">
                      <div className="fs-5 fw-medium text-black mt-2">
                        {item.title}
                      </div>

                      {/* Summary */}
                      <div className="fs-6 fw-normal text-black mt-3">
                        {item.summary.length > 150 && window.innerWidth < 750
                          ? `${item.summary.slice(0, 147)}...`
                          : item.summary}
                      </div>
                    </div>

                    {/* Button */}
                    <ButtonComponent
                      label={
                        localStorage.getItem("i18nextLng") === "bs"
                          ? "Pročitaj →"
                          : "Read more →"
                      }
                      color="#000"
                      backgroundColor="#9FDAF8"
                    />
                  </div>
                </Link>
              </Card>
            ))
          )}
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-center mt-4">
          <Pagination>
            <Pagination.Prev
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
            />
            {[...Array(totalPages).keys()].map((_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                currentPage < totalPages && handlePageChange(currentPage + 1)
              }
            />
          </Pagination>
        </div>
      </Container>
    </div>
  );
};

export default MyComponent;
