import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Logo from "../../assets/mef_logo_black.png";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import { fetchObavijesti } from "../../services/apiService";
import styles from "./Obavijesti.module.css";
import { useTranslation } from "react-i18next";

// Custom arrow components
const NextArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.nextArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &rarr;
  </div>
);

const PrevArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.prevArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &larr;
  </div>
);

const NewsCarousel = () => {
  const [obavijesti, setObavijesti] = useState([]);
  const [isNextArrowVisible, setIsNextArrowVisible] = useState(true);
  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState(false); // Initially hide Prev arrow
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchObavijesti(); // Fetch data using the service function
        setObavijesti(data);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching news:", error);
      }
    };

    loadData(); // Call the function to load the data
  }, [i18n.language]);

  const handleSlideChange = (current) => {
    const slidesToShow = window.innerWidth > 768 ? 3 : 1; // Determine slides to show based on screen width
    const totalSlides = obavijesti.length;

    setIsPrevArrowVisible(current > 0); // Show Prev arrow if not on the first slide
    setIsNextArrowVisible(current + slidesToShow < totalSlides); // Show Next arrow if not on the last slide
  };

  const sliderSettings = (isDesktop) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isDesktop ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow visible={isNextArrowVisible} />,
    prevArrow: <PrevArrow visible={isPrevArrowVisible} />,
    afterChange: handleSlideChange,
  });

  return (
    <section
      className={`container__wrapper bg-light`}
      style={{ minHeight: "min-content" }}
      id="obavjestenja"
    >
      <div className="container">
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <div className="mb-3">
            <h2 className="section_title">{t("announcementSection.title")}</h2>
            <p className="section_subtitle">
              {t("announcementSection.subtitle")}
            </p>
          </div>
          <Link
            to={`/aktuelnosti/obavijesti`}
            style={{ textDecoration: "none", color: "inherit" }}
            className="section__buttons"
          >
            <ButtonComponent
              label={t("announcementSection.buttonText")}
              color="#ffffff"
              backgroundColor="#051435"
            />
          </Link>
        </div>

        <Slider {...sliderSettings(window.innerWidth > 768)}>
          {obavijesti.map((news, index) => (
            <div key={index} style={{ padding: "0 30px" }}>
              <Link
                to={`/aktuelnosti/obavijesti/${news.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() =>
                  localStorage.setItem(
                    "detailSlug",
                    JSON.stringify({
                      bsSlug: news.slug_bs,
                      enSlug: news.slug_en,
                    })
                  )
                }
              >
                <div className={styles.cardContainer}>
                  <div className={styles.cardHeader}>
                    <img src={Logo} alt="Logo" className={styles.cardImage} />
                    <span className={styles.cardDate}>{news.date}</span>
                  </div>
                  <div className={styles.cardContent}>
                    <h5 className="card__title">{news.title}</h5>
                    <p className={styles.cardSummary}>{/* {news.summary} */}</p>
                    <ButtonComponent
                      label={t("announcementSection.readMore")}
                      color="#000"
                      backgroundColor="#9FDAF8"
                      className={styles.cardButton}
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default NewsCarousel;
