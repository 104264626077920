import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom"; // Import Router
import AppRouter from "./routes";
import Animation from "./components/AnimacijaLinije";
import ScrollTo from "./components/SkrolIkona";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n/i18n";

import { AuthProvider } from "./contexts/AuthContext";

function App() {
  useEffect(() => {
    if (!window.location.pathname.includes("/aktuelnosti/novosti/")) {
      localStorage.removeItem("testSlug");
      localStorage.removeItem("testSlugEn");
    }
  }, []);
  return (
    <Router>
      <AuthProvider>
        <Animation />
        <AppRouter />
        <ScrollTo />
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
}

export default App;
