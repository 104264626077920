import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MainBanner from "../../../components/Baner";
import ButtonComponent from "../../../components/ButtonComponent";
import { fetchAllNovosti } from "../../../services/apiService";
import novostiBanner from "../../../assets/novosti.jpg";
import style from "./NovostiLista.module.css";
import { useTranslation } from "react-i18next";

const NewsComponent = () => {
  const [novosti, setNovosti] = useState([]);
  const [newsNum, setNewsNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 6;
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  // const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllNovosti(currentPage, titleFilter);
        setNewsNum(data.count);
        setNovosti(data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, titleFilter, i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page }); // Update the URL query parameter
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    setSearchParams({ page: 1 }); // Reset to the first page when filtering
  };

  const totalPages = Math.ceil(newsNum / itemsPerPage);

  document.title = t("newsSection.metaTitle");
    
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", t("newsSection.metaDescription"));

  return (
    <>
      <MainBanner
        title={t("newsSection.title")}
        styleImage={true}
        bannerImage={novostiBanner}
        description={t("newsSection.subtitle")}
      />
      <div className="container__wrapper bg-white">
        <Container>
          <div className="d-flex justify-content-end mb-4">
            <InputGroup style={{ width: "100%", maxWidth: "240px" }}>
              <FormControl
                placeholder={i18n.language === "bs" ? "Pretraži" : "Search"}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            <Row className="gy-4">
              {isLoading ? (
                <div className="d-flex justify-content-center my-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : novosti.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <p>{i18n.language === "bs" ? "Nema podataka" : "No data"}</p>
                </div>
              ) : (
                novosti.slice(0, 6).map((news) => (
                  <Col key={news.slug} xs={12} sm={6} md={4}>
                    <Link
                      to={`/aktuelnosti/novosti/${news.slug}`}
                      state={{ fromPage: currentPage }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className={style.cardWrapper}>
                        <img
                          src={news.image}
                          alt={news.title}
                          className={style.imageStyle}
                        />
                        <div className={style.newsDate}>{news.date}</div>
                        <div className={style.newsContent}>
                          <h5>
                            {news.title.length > 95
                              ? news.title.substring(0, 95) + "..."
                              : news.title}
                          </h5>

                          <p className={style.newsSummary}>
                            {news.summary.length > 140
                              ? news.summary.substring(0, 180) + "..."
                              : news.summary}
                          </p>
                          <ButtonComponent
                            label={t("newsSection.readMore")}
                            color="#000"
                            backgroundColor="#9FDAF8"
                          />
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))
              )}
            </Row>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Pagination>
              <Pagination.Prev
                onClick={() =>
                  currentPage > 1 && handlePageChange(currentPage - 1)
                }
              />
              {[...Array(totalPages).keys()].map((_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && handlePageChange(currentPage + 1)
                }
              />
            </Pagination>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewsComponent;
