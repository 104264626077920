import React, { useState, useEffect } from "react";
import {
  FaArrowLeft,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaLinkedin,
  FaGoogle,
  FaResearchgate,
} from "react-icons/fa";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import style from "./NastavnoOsobljeDetail.module.css";
import { useTranslation } from "react-i18next"; // Import i18n hook
import { fetchProfesoriDetail } from "../../../services/apiService";

const ProfessorDetail = () => {
  const { t, i18n } = useTranslation(); // Access translation and language functions
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [professor, setProfessor] = useState(null);

  useEffect(() => {
    const fetchProfessor = async () => {
      try {
        const data = await fetchProfesoriDetail(slug);
        setProfessor(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching professor:", error);
      }
    };

    fetchProfessor();
  }, [i18n.language]);

  useEffect(() => {
    if (professor) {
      // Update meta tags
      document.title = t("professorDetail.metaTitle", {
        rank: professor.short_rank,
        firstName: professor.user.first_name,
        lastName: professor.user.last_name,
      });
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", t("professorDetail.metaDescription"));
    }
  }, [professor, i18n.language, t]);

  if (!professor) {
    return <div>{t("loading")}</div>;
  }

  const handleBackNavigation = () => {
    try {
      navigate(-1);
    } catch (error) {
      const fallbackRoute =
        i18n.language === "en"
          ? "/en/fakultet/nastavno-osoblje/"
          : "/fakultet/nastavno-osoblje/";
      navigate(fallbackRoute);
    }
  };

  return (
    <div className="bg-white container__wrapper" style={{ marginTop: "50px" }}>
      <Container>
        {/* Back button */}
        <div onClick={handleBackNavigation} className={style.backButton}>
          <FaArrowLeft className="me-2" /> {t("professorDetail.back")}
        </div>

        <Row style={{ display: "flex", justifyContent: "space-around" }}>
          {/* Professor Information Section */}
          <Col
            xs={12}
            md={4}
            lg={4}
            className={style.contentWrapper}
            style={{ marginBottom: "1.5rem" }}
          >
            <div className="d-flex mb-3">
              <img
                className="professor-image avatar me-3"
                src={professor.user.image}
                alt={`${professor.user.first_name} ${professor.user.last_name}`}
              />
              <div>
                <p style={{ fontSize: "18px", fontWeight: "500", margin: "0" }}>
                  {professor.short_rank} {professor.user.first_name}{" "}
                  {professor.user.last_name}
                </p>
                <p
                  className="text-muted"
                  style={{
                    fontSize: "12px",
                    marginBottom: "0",
                  }}
                >
                  {professor.full_rank}
                </p>
              </div>
            </div>

            <hr style={{ borderColor: "#DDDDDD" }} />

            {/* Contact Information */}
            <div className="mt-3">
              <div className="mb-2 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t("professorDetail.email")}</Tooltip>}
                >
                  <div target="_blank" style={{ display: "inline-block" }}>
                    <FaEnvelope className="me-2" />
                  </div>
                </OverlayTrigger>
                <a
                  href={`mailto:${professor.user.email}`}
                  style={{ color: "black" }}
                >
                  {professor.user.email}
                </a>
              </div>

              <div className="mb-2 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t("professorDetail.phone")}</Tooltip>}
                >
                  <div target="_blank" style={{ display: "inline-block" }}>
                    <FaPhone className="me-2" />
                  </div>
                </OverlayTrigger>
                {professor.phone || t("professorDetail.notAvailable")}
              </div>

              <div className="mb-2 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t("professorDetail.office")}</Tooltip>}
                >
                  <div target="_blank" style={{ display: "inline-block" }}>
                    <FaBuilding className="me-2" />
                  </div>
                </OverlayTrigger>
                {professor.office || t("professorDetail.notAvailable")}
              </div>
            </div>

            <hr className={style.borderBottom} />

            {/* Consultation and Social Links */}
            <div>
              <div className="mb-2">
                <strong>{t("professorDetail.consultation")}</strong>{" "}
                {professor.consultation || t("professorDetail.notAvailable")}
              </div>
              <hr className={style.borderBottom} />
              <div className="d-flex">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>LinkedIn</Tooltip>}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(professor.linkedin, "_blank");
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <FaLinkedin style={{ color: "black" }} />
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>ResearchGate</Tooltip>}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(professor.research_gate, "_blank");
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <FaResearchgate style={{ color: "black" }} />
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Google Scholar</Tooltip>}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(professor.google_scholar, "_blank");
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <FaGoogle style={{ color: "black" }} />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </Col>

          {/* Tabbed Section for Curriculum Vitae and Knjige */}
          <Col xs={12} md={7} className={style.contentWrapper}>
            <Tabs
              defaultActiveKey="cv"
              id="professor-details-tabs"
              className="mb-3"
            >
              <Tab eventKey="cv" title={t("professorDetail.curriculumVitae")}>
                <h5>{t("professorDetail.curriculumVitae")}</h5>
                <hr className={style.borderBottom} />
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      professor.cv ||
                      `<p>${t("professorDetail.notAvailable")}</p>`,
                  }}
                  className={style.htmlWrapper}
                />
              </Tab>
              <Tab eventKey="knjige" title={t("professorDetail.books")}>
                <h5>{t("professorDetail.books")}</h5>
                <hr className={style.borderBottom} />
                <p>{t("professorDetail.notAvailable")}</p>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfessorDetail;
