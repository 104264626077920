import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import avatarM from "../../../assets/avatar_M.png"; // Male avatar
import avatarF from "../../../assets/avatar_F.png"; // Female avatar

const StaffCards = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="row ">
        {/* Card 1 */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
          <div
            className="card h-100 border rounded-3"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #dee2e6",
            }}
          >
            <div className="card-body p-4">
              <div className="d-flex align-items-center justify-content-start mb-3">
                <div
                  className="rounded-circle bg-light d-flex align-items-center justify-content-center me-3"
                  style={{
                    width: "60px",
                    height: "60px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={avatarF}
                    alt="Adisa Dževlan"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div>
                  <h6 className="card-title mb-1">Adisa Dževlan</h6>
                  <p className="mb-0 fs-7">{t("biblioteka.service")}</p>
                </div>
              </div>
              <hr />
              <p className="mb-1 mt-5">
                <FaEnvelope className="me-2" />
                biblioteka@mef.unsa.ba
              </p>
              <p>
                <FaPhone className="me-2" />
                +387 33 729 814
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffCards;
