import React, { useState, useEffect } from "react";
import DocumentsSection from "../../../components/DocumentCard";
import { fetchCuriculum } from "../../../services/apiService";

const NastavniPlan = () => {
  const [items, setItems] = useState([]); // State to hold the fetched items
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error state

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const data = await fetchCuriculum();
        setItems(data);
      } catch (err) {}
    };

    fetchData();
  }, []); // Run only once when the component mounts

  return (
    <section className="bg-light">
      <div className="row">
        <DocumentsSection documents={items} bgClass="bg-light" />
      </div>
    </section>
  );
};

export default NastavniPlan;
