import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainBanner from "../../../components/Baner";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import ButtonComponent from "../../../components/ButtonComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "react-slick"; // Carousel for images and departments
import "../../../styles/global.css"; // Optional: to handle additional styling if needed
import { fetchOdsjeciDetail } from "../../../services/apiService";
import Gallery from "../../../components/Galerija";
import styles from "./OdsjekDetaljno.module.css";
import { useTranslation } from "react-i18next";

// const departments = [
//   {
//     slug_bs: "katedra-za-matematiku-i-fiziku",
//     slug_en: "department-of-mathematics-and-physics",
//   },
//   {
//     slug_bs: "katedra-za-mehaniku",
//     slug_en: "departments-of-solid-mechanics",
//   },
//   {
//     slug_bs: "odsjek-za-energetiku-procesnu-tehniku-i-okolinsko-inzinjerstvo",
//     slug_en:
//       "department-of-energy-process-engineering-and-environmental-engineering",
//   },
//   {
//     slug_bs: "odsjek-za-industrijsko-inzinjerstvo-i-menadzment",
//     slug_en: "department-of-industrial-engineering-and-management",
//   },
//   {
//     slug_bs: "odsjek-za-masinske-konstrukcije",
//     slug_en: "department-of-mechanical-design",
//   },
//   {
//     slug_bs: "odsjek-za-masinski-proizvodni-inzinjering",
//     slug_en: "department-of-machinery-production-engineering",
//   },
//   {
//     slug_bs: "odsjek-za-motore-i-vozila",
//     slug_en: "department-of-vehicle-engineering",
//   },
//   {
//     slug_bs: "odsjek-za-odbrambene-tehnologije",
//     slug_en: "department-of-defense-technology",
//   },
//   {
//     slug_bs: "odsjek-za-tehnologiju-drveta",
//     slug_en: "department-of-wood-technology",
//   },
// ];

const NextArrow = ({ onClick }) => (
  <div className={`${styles.arrowStyle} ${styles.nextArrow}`} onClick={onClick}>
    &rarr;
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className={`${styles.arrowStyle} ${styles.prevArrow}`} onClick={onClick}>
    &larr;
  </div>
);

const DepartmentDetail = () => {
  const { slug } = useParams(); // Get department slug from URL
  const navigate = useNavigate();
  const [department, setDepartment] = useState(null);
  const [numOfDepartments, setNumOfDepartments] = useState(1);
  const bannerRef = useRef(null);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const data = await fetchOdsjeciDetail(slug);
        setDepartment(data);
        setNumOfDepartments(data.num_of_katedri);
        // Set page metadata
        document.title = data.meta_title;
        document.querySelector('meta[name="description"]').content =
          data.meta_description;
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching department details:", error);
      }
    };

    fetchDepartment();
  }, [slug, i18n.language]);

  if (!department) return <p>Loading...</p>;

  const handleProfessorClick = (professorSlug) => {
    navigate(`/fakultet/nastavno-osoblje/${professorSlug}`);
  };

  const handleOtherDepartmentClick = (deptSlug) => {
    navigate(`/odsjeci/${deptSlug}`);
  };

  const gallerySettings = {
    arrows: false,
    dots: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 2,
          centerPadding: "40px",
        },
      },
    ],
  };

  const items = [
    {
      nameKey:
        department.name.substring(0, 7) === "Katedra"
          ? "navbarOdsjeci.aboutKatedra"
          : "navbarOdsjeci.aboutOdsjeku",
      link: "#oodsjeku",
    },
    {
      nameKey:
        department.name.substring(0, 7) === "Katedra"
          ? "navbarOdsjeci.headOfKatedra"
          : "navbarOdsjeci.headOfOdsjeku",
      link: "#sef-katedre",
    },
    {
      nameKey:
        department.name.substring(0, 7) === "Katedra"
          ? "navbarOdsjeci.membersOfKatedra"
          : "navbarOdsjeci.membersOfOdsjeku",
      link: "#clanovi-katedre",
    },
    {
      nameKey:
        department.name.substring(0, 7) === "Katedra"
          ? "navbarOdsjeci.activitiesOfKatedra"
          : "navbarOdsjeci.activitiesOfOdsjeku",
      link: "#aktivnosti-katedre",
    },
    { nameKey: "navbarOdsjeci.gallery", link: "#galerija" },
    {
      nameKey: "navbarOdsjeci.otherDepartments",
      link: "#ostali-odsjeci",
    },
  ];

  if (numOfDepartments === 2) {
    items.splice(1, 0, {
      nameKey: "navbarOdsjeci.headOfDeptAndChairs", // Head of Department and Chairs
      link: "#sef-katedre",
    });

    // Remove the item where name is "Šef Odsjeka"
    const indexToRemove = items.findIndex(
      (item) => item.nameKey === "navbarOdsjeci.headOfOdsjeku"
    );
    if (indexToRemove !== -1) {
      items.splice(indexToRemove, 1);
    }
  }

  // Meta Tags
  document.title = department.meta_title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", department.meta_description);

  return (
    <div>
      <div ref={bannerRef}>
        <MainBanner title={department.name} bannerImage={department.cover} />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />

      {/* Summary Section */}
      <section className="bg-white container__wrapper" id="oodsjeku">
        <div className="container d-flex flex-wrap justify-content-between align-items-center html__text">
          <div className="col-md-7">
            <p
              className="card_subtitle"
              dangerouslySetInnerHTML={{ __html: department.summary }}
            ></p>
          </div>
          <div className="col-md-4">
            <img
              src={department.image}
              alt={department.name}
              class={styles.imageStyle}
            />
          </div>
        </div>
      </section>

      {/* Department Chef Section */}
      <section
        style={{ background: "#F3F6F8" }}
        className="container__wrapper"
        id="sef-katedre"
      >
        <div className="container">
          {numOfDepartments === 1 ? (
            <div>
              <p className="card_title fw-bold">
                {t(
                  department.slug.includes("katedra")
                    ? "odsjeciDetaljno.headOfKatedra"
                    : "odsjeciDetaljno.headOfOdsjeku"
                )}
              </p>
              <div
                className={`${styles.cardStyle} card col-12 col-md-6`}
                onClick={() =>
                  handleProfessorClick(department.katedre[0].head.slug)
                }
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={department.katedre[0].head.image}
                    alt={department.katedre[0].head.full_name}
                    width="50"
                    height="50"
                    className="me-3 rounded-circle professor-image"
                  />
                  <div>
                    <h5 style={{ fontSize: "18px", margin: "0" }}>
                      {department.katedre[0].head.short_rank}{" "}
                      {department.katedre[0].head.full_name}
                    </h5>
                    <p style={{ fontSize: "12px", margin: "0" }}>
                      {department.katedre[0].head.rank}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div id="sef-katedre">
              <p className="card_title fw-bold">
                {t("odsjeciDetaljno.headOfDeptAndChairs")}
              </p>
              <p>{department.name}</p>
              <div
                className={`${styles.cardStyle} card col-12 col-md-6`}
                onClick={() => handleProfessorClick(department.head.slug)}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={department.head.image}
                    alt={department.head.full_name}
                    width="50"
                    height="50"
                    className="me-3 rounded-circle professor-image"
                  />
                  <div>
                    <h5 className="heading-subtitle" style={{ color: "#000" }}>
                      {department.head.short_rank} {department.head.full_name}
                    </h5>
                    <p className="professor-card_subtitle ">
                      {department.head.rank}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex flex-column flex-md-row gap-3 gap-md-3">
                  <div className="col-12 col-md-6">
                    <p>{department.katedre[0].name}</p>
                    <div
                      className={`${styles.cardStyle} card col-12`}
                      onClick={() =>
                        handleProfessorClick(department.katedre[0].head.slug)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={department.katedre[0].head.image}
                          alt={department.katedre[0].head.full_name}
                          width="50"
                          height="50"
                          className="me-3 rounded-circle professor-image"
                        />
                        <div>
                          <h5
                            className="heading-subtitle"
                            style={{ color: "#000" }}
                          >
                            {department.katedre[0].head.short_rank}{" "}
                            {department.katedre[0].head.full_name}
                          </h5>
                          <p className="professor-card_subtitle ">
                            {department.katedre[0].head.rank}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <p>{department.katedre[1].name}</p>
                    <div
                      className={`${styles.cardStyle} card col-12`}
                      onClick={() =>
                        handleProfessorClick(department.katedre[1].head.slug)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={department.katedre[1].head.image}
                          alt={department.katedre[1].head.full_name}
                          width="50"
                          height="50"
                          className="me-3 rounded-circle professor-image"
                        />
                        <div>
                          <h5
                            className="heading-subtitle"
                            style={{ color: "#000" }}
                          >
                            {department.katedre[1].head.short_rank}{" "}
                            {department.katedre[1].head.full_name}
                          </h5>
                          <p className="professor-card_subtitle ">
                            {department.katedre[1].head.rank}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Professors Section */}
      <section
        style={{ background: "#FFFFFF" }}
        id="clanovi-katedre"
        className="container__wrapper"
      >
        <div className="container">
          <p className="card_title fw-bold">
            {t(
              department.slug.includes("katedra")
                ? "odsjeciDetaljno.membersOfKatedra"
                : "odsjeciDetaljno.membersOfDept"
            )}
          </p>
          <div className="row g-4">
            <p>{department.katedre[0].name}</p>
            {department.katedre[0].members.map((member) => (
              <div
                key={member.slug}
                className="col-md-6"
                onClick={() => handleProfessorClick(member.slug)}
                style={{ cursor: "pointer" }}
              >
                <div className={`${styles.cardStyle}`}>
                  <div className="card-body d-flex align-items-center">
                    <img
                      src={member.image}
                      alt={member.full_name}
                      width="50"
                      height="50"
                      className="me-3 rounded-circle professor-image"
                    />
                    <div>
                      <h5
                        className="heading-subtitle"
                        style={{ color: "#000" }}
                      >
                        {member.short_rank} {member.full_name}
                      </h5>
                      <p className="professor-card_subtitle">{member.rank}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {department.katedre[1] && (
            <div className="row g-4 mt-2 mx-md-0">
              <p>{department.katedre[1].name}</p>
              {department.katedre[1].members.map((member) => (
                <div
                  key={member.slug}
                  className="col-md-6"
                  onClick={() => handleProfessorClick(member.slug)}
                  style={{ cursor: "pointer" }}
                >
                  <div className={`${styles.cardStyle}`}>
                    <div className="card-body d-flex align-items-center">
                      <img
                        src={member.image}
                        alt={member.full_name}
                        width="50"
                        height="50"
                        className="me-3 rounded-circle professor-image"
                      />
                      <div>
                        <h5
                          className="heading-subtitle"
                          style={{ color: "#000" }}
                        >
                          {member.short_rank} {member.full_name}
                        </h5>
                        <p className="professor-card_subtitle ">
                          {member.rank}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* Content Section */}
      <section
        style={{ background: "#F3F6F8" }}
        id="aktivnosti-katedre"
        className="container__wrapper "
      >
        <div className="container">
          <div
            className="html__wrapper html__text"
            style={{
              overflow: "hidden",
              margin: "0 -15px",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: department.content }}
              style={{ color: "#000" }}
            ></div>
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      {department.gallery && department.gallery.length > 0 && (
        <Gallery images={department.gallery} />
      )}

      {/* Other Departments Section */}
      <section
        className="bg-light"
        id="ostali-odsjeci"
        style={{ minHeight: "390px", padding: "50px 0" }}
      >
        <div className="container" style={{ maxWidth: "100%" }}>
          <Slider {...gallerySettings} className="slider__wrapper">
            {department.other_departments.map((dept) => (
              <div
                key={dept.slug}
                style={{ margin: "0 15px", cursor: "pointer" }}
              >
                <div
                  class={`${styles.departmentCard} d-flex flex-column gap-3`}
                >
                  <img
                    src={dept.icon}
                    alt={`${dept.name} icon`}
                    width="30"
                    height="30"
                  />
                  <h4 className="card__title" style={{ marginBottom: "auto" }}>
                    {dept.name}
                  </h4>
                  <ButtonComponent
                    label={t("facultyDepartments.readMore")}
                    color="#000"
                    backgroundColor="#9FDAF8"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOtherDepartmentClick(dept.slug);
                    }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default DepartmentDetail;
