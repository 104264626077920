import React, { useEffect, useState } from "react";
import MainBanner from "../../../components/Baner";
import ObavijestiList from "./ObavijestiList";
import { useTranslation } from "react-i18next";
import obavijestiBanner from "../../../assets/obavijesti.jpg";

const ObavijestiComponent = () => {
  const { t, i18n } = useTranslation();

  document.title = t("announcementSection.metaTitle");
    
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", t("announcementSection.metaDescription"));

  return (
    <>
      <MainBanner
        title={t("obavijestiDetaljno.bannerTitle")}
        bannerImage={obavijestiBanner}
        description={t("obavijestiDetaljno.bannerDescription")}
      />
      <ObavijestiList />
    </>
  );
};

export default ObavijestiComponent;
