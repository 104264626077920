import React, { createContext, useState, useEffect } from "react";
import {
  getAccessToken,
  isTokenExpired,
  clearTokens,
  getRefreshToken,
  refreshAccessToken,
} from "../utils/tokenUtils";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [user, setUser] = useState(null);
  const [userInitials, setUserInitials] = useState("");
  const [isModerator, setIsModerator] = useState(false);
  const navigate = useNavigate();

  const getInitials = (fullName) => {
    const name = fullName.full_name;
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0).toUpperCase())
      .join("");
    setUserInitials(initials);
  };

  useEffect(() => {
    const token = getAccessToken();

    if (token && !isTokenExpired(token)) {
      setIsAuthenticated(true);
      setUser(parseUserFromToken(token));
      getInitials(parseUserFromToken(token));
    } else {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        refreshAccessToken(refreshToken)
          .then((newAccessToken) => {
            if (newAccessToken) {
              setIsAuthenticated(true);
              setUser(parseUserFromToken(newAccessToken));
              getInitials(parseUserFromToken(newAccessToken));
            }
          })
          .catch(() => {
            setIsAuthenticated(false);
            setUser(null);
            clearTokens();
          });
      } else {
        setIsAuthenticated(false);
        setUser(null);
        clearTokens();
      }
    }
  }, []);

  const getLanguageMessage = (key) => {
    const language = localStorage.getItem("i18nextLng") || "bs";
    const messages = {
      login: {
        en: "Logged in successfully!",
        bs: "Uspješno ste se prijavili!",
      },
      logout: {
        en: "Logged out successfully!",
        bs: "Uspješno ste se odjavili!",
      },
    };
    return messages[key][language];
  };

  const logout = () => {
    clearTokens();
    setIsAuthenticated(false);
    setUser(null);
    toast.success(getLanguageMessage("logout"), {
      autoClose: 1000,
    });
    localStorage.getItem("language") === "en" ? navigate("/en") : navigate("/");
  };

  const login = (data) => {
    const accessToken = data.access;
    const refreshToken = data.refresh;
    setIsModerator(data.is_moderator);
    localStorage.setItem("isModerator", data.is_moderator);
    localStorage.setItem("user_image", data.image);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    toast.success(getLanguageMessage("login"), {
      autoClose: 1000,
    });

    // Set the authentication state
    setIsAuthenticated(true);
    setUser(parseUserFromToken(accessToken));
    getInitials(parseUserFromToken(accessToken));
    // setProfileImage(image);
  };

  const parseUserFromToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload; // Adjust based on your token's structure
    } catch (error) {
      console.error("Error parsing token:", error);
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        logout,
        userInitials,
        login,
        profileImage,
        isModerator,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
