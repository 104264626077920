import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import MainBanner from "../../../components/Baner";
import "../../../styles/global.css";
import { fetchCuriculumDetail } from "../../../services/apiService";
import DocumentsSection from "../../../components/DocumentCard";
import style from "./CiklusDetaljno.module.css";
import { useTranslation } from "react-i18next";

const Section = ({ bgClass, header, items, id, htmlContent }) => (
  <section className={`${bgClass} py-5`} id={id}>
    <div className="container">
      {header && (
        <div
          className="d-flex align-items-center pb-3 mb-5"
          style={{ borderBottom: "1px solid #DDDDDD" }}
        >
          <div className={style.headerCycleWrapper}></div>
          <h2 className="fs-6 fw-medium mb-0">{header}</h2>
        </div>
      )}
      <div className="row">
        {htmlContent ? (
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className="html__wrapper html__text"
          />
        ) : (
          <DocumentsSection documents={items} bgClass={bgClass} />
        )}
      </div>
    </div>
  </section>
);

const CurriculumDetail = () => {
  const [data, setData] = useState(null);
  const { slug } = useParams();
  const bannerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  window.history.replaceState(
    {},
    "",
    `${i18n.language === "en" ? "/en" : ""}/nastava/${slug}`
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCuriculumDetail(slug);
        setData(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [i18n.language, slug, navigate]);

  if (!data) return <p>Loading...</p>;

  const items = [
    {
      nameKey: t("ciklusiDetaljno.sectionHeaders.aboutCycle"),
      link: "#ociklusu",
    },
    {
      nameKey: t("ciklusiDetaljno.sectionHeaders.curriculum"),
      link: "#nastavniplan",
    },
    { nameKey: t("ciklusiDetaljno.sectionHeaders.syllabus"), link: "#silabus" },
    {
      nameKey: t("ciklusiDetaljno.sectionHeaders.classSchedules"),
      link: "#rasporedinastave",
    },
    {
      nameKey: t("ciklusiDetaljno.sectionHeaders.examSchedules"),
      link: "#rasporedpolaganjaispita",
    },
    {
      nameKey: t("ciklusiDetaljno.sectionHeaders.otherDocuments"),
      link: "#ostalidokumenti",
    },
  ];

  // Meta Tags
  document.title = data.meta_title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", data.meta_description);

  let currentBgClass = "bg-white";
  let isFirstSection = true;

  const toggleBgClass = () => {
    if (isFirstSection) {
      isFirstSection = false;
      return "bg-white";
    }
    currentBgClass = currentBgClass === "bg-white" ? "bg-light" : "bg-white";
    return currentBgClass;
  };

  const renderSection = (condition, props) => {
    if (condition) {
      const section = (
        <Section
          bgClass={toggleBgClass()}
          {...props}
        />
      );
      return section;
    }
    return null;
  };

  return (
    <div>
      <div ref={bannerRef}>
        <MainBanner
          title={data.title}
          bannerImage={data.cover}
          description={data.header}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />

      <div>
        {renderSection(true, {
          id: "ociklusu",
          htmlContent: data.content,
        })}

        {renderSection(data.nastavni_planovi.length > 0, {
          items: data.nastavni_planovi,
          id: "nastavniplan",
        })}

        {renderSection(data.silabusi.length > 0, {
          items: data.silabusi,
          id: "silabus",
        })}

        {renderSection(data.rasporedi_nastave.length > 0, {
          header: data.header_raspored_nastave,
          items: data.rasporedi_nastave,
          id: "rasporedinastave",
        })}

        {renderSection(data.rasporedi_ispita.length > 0, {
          header: data.header_raspored_ispita,
          items: data.rasporedi_ispita,
          id: "rasporedpolaganjaispita",
        })}

        {renderSection(data.ostali.length > 0, {
          items: data.ostali,
          id: "ostalidokumenti",
        })}
      </div>
    </div>
  );
};

export default CurriculumDetail;
