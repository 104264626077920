import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommingSoonImage from "../assets/mef-slike/coomingsoon.png";
import { useTranslation } from "react-i18next";

const CommingSoon = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate("/");
  };

  // useEffect(() => {
  //   // Update the URL when the component is mounted
  //   navigate("/commingsoonpage", { replace: true });
  // }, [navigate]);

  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Row className="text-center">
        <Col>
          <img
            src={CommingSoonImage}
            alt="not_found_image"
            style={{ display: "block", margin: "0 auto", width: "100%" }}
          />

          <h1 className="mb-3 text-white my-4">{t("commingSoon.title")}</h1>
          <p className="text-white mb-5">{t("commingSoon.description")}</p>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleGoBack}
          >
            {t("notFound.backToHomepage")}
            {/* {localStorage.getItem("language") === "en"
              ? "Back to Homepage  →"
              : "Natrag na početnu  →"} */}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CommingSoon;
