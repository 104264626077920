// router.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// import { getLocalizedPath } from "./helpers/helpers";

import NavbarComponent from "./components/NavigacijaStranice";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import DepartmentDetail from "./pages/Nastava/Departments/OdsjekDetaljno";
import NotFound from "./components/NotFound";
import Spinner from "./components/Spinner";

import Pocetna from "./pages/Pocetna";
import FakultetPage from "./pages/Fakultet";

import NovostiPage from "./pages/Aktuelnosti";
import NewsDetail from "./pages/Aktuelnosti/Novosti/NovostiDetaljno";
import ObavijestiComponent from "./pages/Aktuelnosti/Obavijesti/ObavijestiComponent";
import ObavijestDetail from "./pages/Aktuelnosti/Obavijesti/ObavijestDetail";
import NastavnoOsobljeComponent from "./pages/Fakultet/Nastavno osoblje/NastavnoOsoblje";
import NastavnoOsobljeDetail from "./pages/Fakultet/Nastavno osoblje/NastavnoOsobljeDetail";
import DocumentComponent from "./pages/Fakultet/Dokumenti/Dokumenti";
import CurriculumDetail from "./pages/Nastava/Curiculum/CiklusDetaljno";
import CommingSoon from "./components/CommingSoon";

import { useTranslation } from "react-i18next";
import Kontakt from "./pages/Fakultet/Kontakt/Kontakt";
import StudentskaSluzba from "./pages/Fakultet/Studentska sluzba/StudentskaSluzba";
import BibliotekaComponent from "./pages/Fakultet/Biblioteka/Biblioteka";
import MasinijadaComponent from "./pages/Aktuelnosti/Masinijada/Masinijada";
import MasinijadaDetaljno from "./pages/Aktuelnosti/Masinijada/MasinijadaDetaljno";

const LanguageRedirect = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const language = i18n.language;

    if (language === "en" && !location.pathname.startsWith("/en")) {
      setRedirecting(true);
      navigate(`/en${location.pathname}`, { replace: true });
    } else if (language === "bs" && location.pathname.startsWith("/en")) {
      setRedirecting(true);
      navigate(location.pathname.replace(/^\/en/, ""), { replace: true });
    } else {
      setRedirecting(false);
    }
  }, [i18n.language, location.pathname, navigate]);

  if (redirecting) {
    return <Spinner />; // Render a loading spinner until redirection is complete
  }

  return children;
};

function AppRouter() {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const language = i18n.language || "bs"; // Default to Bosnian

    if (language && !initialized) {
      i18n.changeLanguage(language).then(() => setInitialized(true));
    }
  }, [i18n, initialized]);

  if (!initialized) {
    return <Spinner />;
  }

  return (
    <>
      <LanguageRedirect>
        <NavbarComponent />
        <ScrollToTop />
        <Routes>
          {/* Home page route */}
          <Route path={"/"} element={<Pocetna />} />
          <Route path={"/en"} element={<Pocetna />} />

          {/* Fakultet routes */}
          <Route path={"/fakultet"} element={<FakultetPage />} />
          <Route path={"en/fakultet"} element={<FakultetPage />} />
          <Route
            path={"/fakultet/nastavno-osoblje"}
            element={<NastavnoOsobljeComponent />}
          />
          <Route
            path={"en/fakultet/nastavno-osoblje"}
            element={<NastavnoOsobljeComponent />}
          />
          <Route
            path={"/fakultet/nastavno-osoblje/:slug"}
            element={<NastavnoOsobljeDetail />}
          />
          <Route
            path={"en/fakultet/nastavno-osoblje/:slug"}
            element={<NastavnoOsobljeDetail />}
          />
          <Route path={"/fakultet/dokumenti"} element={<DocumentComponent />} />
          <Route
            path={"en/fakultet/dokumenti"}
            element={<DocumentComponent />}
          />

          {/* <Route
          path="/fakultet-nastavno-osoblje"
          element={<NastavnoOsobljePage />}
        /> */}

          <Route path={"/fakultet-javne-nabavke"} element={<CommingSoon />} />
          <Route path={"en/fakultet-javne-nabavke"} element={<CommingSoon />} />
          <Route path={"/fakultet-historija"} element={<CommingSoon />} />
          <Route path={"en/fakultet-historija"} element={<CommingSoon />} />

          {/* <Route
            path={getLocalizedPath("/commingsoonpage")}
            element={<CommingSoon />}
          /> */}

          {/* Aktuelnosti routes */}
          {/* <Route path="/aktuelnosti/obavijesti" element={<ObavijestiPage />} /> */}
          <Route path={"/aktuelnosti/novosti"} element={<NovostiPage />} />
          <Route path={"en/aktuelnosti/novosti"} element={<NovostiPage />} />
          <Route path={"/aktuelnosti/novosti/:slug"} element={<NewsDetail />} />
          <Route
            path={"en/aktuelnosti/novosti/:slug"}
            element={<NewsDetail />}
          />

          <Route
            path={"/aktuelnosti/obavijesti"}
            element={<ObavijestiComponent />}
          />
          <Route
            path={"en/aktuelnosti/obavijesti"}
            element={<ObavijestiComponent />}
          />
          <Route
            path={"/aktuelnosti/obavijesti/:slug"}
            element={<ObavijestDetail />}
          />
          <Route
            path={"en/aktuelnosti/obavijesti/:slug"}
            element={<ObavijestDetail />}
          />
          <Route
            path={"/aktuelnosti/masinijada"}
            element={<MasinijadaComponent />}
          />
          <Route
            path={"/aktuelnosti/masinijada/:slug"}
            element={<MasinijadaDetaljno />}
          />
          <Route
            path={"en/aktuelnosti/masinijada"}
            element={<MasinijadaComponent />}
          />
          <Route
            path={"en/aktuelnosti/masinijada/:slug"}
            element={<MasinijadaDetaljno />}
          />
          <Route path={"/fakultet/kontakt"} element={<Kontakt />} />
          <Route path={"en/fakultet/kontakt"} element={<Kontakt />} />

          <Route
            path={"/fakultet/studentska-sluzba"}
            element={<StudentskaSluzba />}
          />
          <Route
            path={"en/fakultet/studentska-sluzba"}
            element={<StudentskaSluzba />}
          />
          <Route
            path={"/fakultet/biblioteka"}
            element={<BibliotekaComponent />}
          />
          <Route
            path={"en/fakultet/biblioteka"}
            element={<BibliotekaComponent />}
          />
          <Route
            path={"/studenti/biblioteka"}
            element={<BibliotekaComponent />}
          />
          <Route
            path={"en/studenti/biblioteka"}
            element={<BibliotekaComponent />}
          />

          <Route path={"/coomingsoon"} element={<CommingSoon />} />

          {/* <Route path="/aktuelnosti/seminari" element={<SeminariPage />} />
        <Route
          path="/aktuelnosti/konferencije"
          element={<KonferencijePage />}
        /> */}
          {/* <Route path="/aktuelnosti/masinijada" element={<MasinijadaPage />} />
        <Route path="/aktuelnosti/fondovi" element={<FondoviPage />} /> */}

          {/* Studenti routes */}
          {/* <Route path="/studenti-link1" element={<StudentLink1 />} /> */}
          {/* Add more routes as needed */}

          {/* Projekti routes */}
          {/* <Route path="/projekti-link1" element={<ProjectLink1 />} /> */}
          {/* Add more routes as needed */}

          {/* Portali routes */}
          {/* /<Route path="/portali-link1" element={<PortalLink1 />} /> */}
          {/* Add more routes as needed */}
          <Route path="*" element={<NotFound />} />
          <Route path={"/odsjeci/:slug"} element={<DepartmentDetail />} />
          <Route path={"en/odsjeci/:slug"} element={<DepartmentDetail />} />
          <Route path={"/nastava/:slug"} element={<CurriculumDetail />} />
          <Route path={"en/nastava/:slug"} element={<CurriculumDetail />} />
        </Routes>
        <Footer />
      </LanguageRedirect>
    </>
  );
}

export default AppRouter;
