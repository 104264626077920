import React, { useRef, useState, useEffect } from "react";

import MainBanner from "../../../components/Baner";
import masinijadaBanner from "../../../assets/mef-slike/masinijada_banner.jpg";
import { useTranslation } from "react-i18next";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import MasinijadaLista from "./MasinijadaLista";
import MasinijadaSlika from "../../../assets/mef-slike/masinijada-mef.jpg";
import { fetchAllMasinijade } from "../../../services/apiService";

const MasinijadaComponent = () => {
  const [masinijade, setMasinijade] = useState([]);
  const bannerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const items = [
    { nameKey: "masinijada.about", link: "#omasinijadi" },
    { nameKey: "masinijada.previous", link: "#prethodne" },
  ];

  document.title = t("masinijada.metaTitle");

  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", t("masinijada.metaDescription"));

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchAllMasinijade();
        setMasinijade(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    loadData();
  }, [i18n.language]);

  return (
    <div>
      <MainBanner
        bannerRef={bannerRef}
        title={t("masinijada.title")}
        styleImage={true}
        bannerImage={masinijadaBanner}
        description={t("masinijada.description")}
      />
      <SectionNavbar items={items} observeRef={bannerRef} />

      <section className="bg-light container__wrapper" id="omasinijadi">
        <div className="container d-flex flex-wrap justify-content-between align-items-center html__text">
          <div className="col-md-7">
            <p className="card_subtitle">{t("masinijada.content1")}</p>
            <p className="card_subtitle">{t("masinijada.content2")}
              <a href="mailto:masinac@mef.unsa.ba">masinac@mef.unsa.ba</a>
            </p>
          </div>
          <div className="col-md-4">
            <img src={MasinijadaSlika} alt={"Masinijada slika"} />
          </div>
        </div>
      </section>
      <div className="container__wrapper bg-white" id="prethodne">
        <MasinijadaLista masinijade={masinijade} />
      </div>
    </div>
  );
};

export default MasinijadaComponent;
