import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaChevronRight, FaDownload } from "react-icons/fa";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import { fetchObavijestiDetail } from "../../../services/apiService";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "./ObavijestDetail.module.css";
import { useTranslation } from "react-i18next";

const ObavijestDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [obavijest, setObavijest] = useState(null);
  const { t, i18n } = useTranslation();

  const mobileView = window.innerWidth <= 768;

  useEffect(() => {
    const fetchObavijest = async () => {
      try {
        const data = await fetchObavijestiDetail(slug);
        setObavijest(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching obavijest:", error);
      }
    };

    fetchObavijest();
  }, [i18n.language]);

  window.history.replaceState(
    {},
    "",
    `${i18n.language === "en" ? "/en" : ""}/aktuelnosti/obavijesti/${slug}`
  );

  if (!obavijest) return <p>Loading...</p>;

  // Meta Tags
  document.title = obavijest.meta_title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", obavijest.meta_description);

  return (
    <>
      <HeadingComponent
        // bannerRef={bannerRef}
        title={obavijest.title}
        date={obavijest.date}
        sectionName={t("obavijestiDetaljno.announcements")}
        fallbackRoute={`${
          i18n.language === "en" ? "/en/" : "/"
        }aktuelnosti/obavijesti/`}
      />

      {/* Main Content Section */}
      <div className="bg-white container__wrapper">
        <div className="container  py-5 px-2">
          {obavijest.image && !mobileView ? (
            <img
              src={obavijest.image}
              className="img-fluid mb-4"
              alt={t("obavijestiDetaljno.imageAltText")}
            />
          ) : obavijest.image_mob && mobileView ? (
            <div className="d-flex justify-content-center">
              <img
                src={obavijest.image_mob}
                className="img-fluid mb-4"
                alt={t("obavijestiDetaljno.imageAltText")}
                style={{ width: "150px" }}
              />
            </div>
          ) : null}

          <h1 class={`${style.mobile__size} text-center mt-4 mb-4 `}>
            {obavijest.h1_text}
          </h1>
          {obavijest.show_summary && <p>{obavijest.summary}</p>}
          <div dangerouslySetInnerHTML={{ __html: obavijest.content }} />
        </div>

        {/* PDF Files Section */}
        <div className="container d-flex flex-wrap gap-3 ">
          {obavijest.documents &&
            obavijest.documents.map((pdf, index) => (
              <div
                key={index}
                className={`${style.pdfContainer} d-flex flex-column justify-content-between`}
              >
                <h5 className="fs-6 fw-medium">{pdf.title}</h5>

                <a
                  href={pdf.file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-dark d-flex align-items-center mt-2"
                >
                  {t("obavijestiDetaljno.downloadText")}
                  <i className="bi bi-download ms-2"></i>
                </a>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ObavijestDetail;
