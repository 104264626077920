import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/global.css";

const DocumentsSection = ({ documents, bgClass = false }) => {
  return (
    <div
      className={bgClass ? bgClass : ""}
      style={{ backgroundColor: bgClass ? "" : "#ffffff" }}
    >
      <Container>
        <Row className="g-3">
          {" "}
          {/* Use Bootstrap grid gap for spacing */}
          {documents &&
            documents.map((pdf, index) => (
              <Col key={index} lg={4} md={6} xs={12}>
                {" "}
                {/* Grid columns */}
                <div className="d-flex flex-column justify-content-between document__wrapper">
                  <h5 className="document__title">{pdf.title}</h5>
                  <a
                    href={pdf.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-dark d-flex align-items-center mt-2"
                  >
                    {localStorage.getItem("i18nextLng") === "en"
                      ? "Download "
                      : "Preuzmi "}{" "}
                    <i className="bi bi-download ms-2"></i>
                  </a>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default DocumentsSection;
